import { FC } from 'react'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text, Divider } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { User, UserLevel } from 'src/core/User/domain/User'
import { getUserLevelText } from 'src/ui/i18n/getUserLevelText'

interface Props {
  user: User
}

export const LevelCoupon: FC<Props> = ({ user }) => {
  const { trans } = useTrans(['common', 'new-reservation'])

  const userLevelText = getUserLevelText(user.level as UserLevel, trans)

  return (
    <Flex direction="column">
      <Text fontStyle="m-700" color="dark" as="p">
        {`${trans('common:header_my-barcelo')} ${userLevelText} `}
        <Text fontStyle="m-300" color="mid">
          ({user.discount}%)
        </Text>
      </Text>
      <Text fontStyle="s-300" color="support-success" as="p">
        {trans('new-reservation:coupons_enjoy-discounts')}
      </Text>

      <Divider dir="horizontal" className="mt-l mb-l" />
    </Flex>
  )
}
